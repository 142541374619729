import React from "react"
import Recaptcha from "react-google-recaptcha"
import { navigate } from "gatsby"
import jsonp from "jsonp"
import queryString from "query-string"
// @material-ui/icons
import Phone from "@material-ui/icons/Phone"
import House from "@material-ui/icons/House"

// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import InfoArea from "../InfoArea/InfoArea.jsx"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import CardHeader from "../Card/CardHeader"
import { Input } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY
const mailchimpUrl = "https://centerconsolesonly.us19.list-manage.com"
const mailchimpUser = "f78bf0fde4fdfa576857869c5"
const mailchimpListId = "f43a862600"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const RSVPRegistration = (props) => {
  const { classes } = props
  const [state, setState] = React.useState({ selectedDate: "", address2: "" })
  const recaptchaRef = React.createRef()
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        subscribeToNewsLetter()
        navigate(form.getAttribute("action"))
      })
      .catch((error) => alert(error))
  }

  const subscribeToNewsLetter = () => {
    const fullName = state.name.split(" ")
    const lastName = fullName.pop()
    const firstName = fullName.join(" ")

    const formData = {
      EMAIL: state.email,
      PHONE: state.phone.replace(/-|\s/g, ""),
      FNAME: firstName,
      LNAME: lastName,
      SVREQDATE: state.selectedDate,
      MAILINGADD: `${state.address} ${state.address2} ${state.city}, ${state.state} ${state.zip} ${state.country}`,
    }

    jsonp(
      `${mailchimpUrl}/subscribe/post-json?u=${mailchimpUser}&amp;id=${mailchimpListId}&${queryString.stringify(
        formData
      )}`,
      { param: "c" },
      (err, data) => {
        console.log("err:", err)
        console.log("data:", data)
      }
    )
  }

  return (
    <GridContainer
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ padding: "1.5rem 16px" }}
    >
      <Card style={style.cardBorder}>
        <CardHeader
          plain
          noShadow
          color="primary"
          className={`${classes.textCenter} ${classes.cardLoginHeader}`}
        >
          <h3 className={classes.cardTitleWhite}>
            EVENT DETAILS & REGISTRATION FORM
          </h3>
        </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={5} className={classes.mlAuto}>
            <InfoArea
              className={classes.infoArea}
              description={
                <div>
                  <h5 style={{ fontWeight: 500 }}>
                    We are truly honored to announce this exclusive boat show
                    event highlighted by the world debut of the Flagship SeaVee
                    450Z. This will truly be a unique event featuring our
                    partners SeaVee Boats, Mercury Marine, Sea Keeper and JL
                    Audio to name a few.
                    <br />
                    <br />
                    We will also be broadcasting the entire event live on the
                    CenterConsolesOnly YouTube Channel for all to
                    experience along with us.
                    <br />
                    <br />
                    <strong>WHEN:</strong>
                    <br />
                    October 3 - 4, 2020
                    <br />
                    <br />
                    <strong>WHERE:</strong>
                    <br />
                    Rybovich Marine Center
                    <br />
                    2010 Avenue B
                    <br />
                    Riviera Beach, FL 33404
                    <br />
                    <br />
                    <strong>HOW:</strong>
                    <br />
                    1. <strong>Virtual:</strong> LIVE Streaming on the
                    <a
                      href={"https://youtube.com/centerconsolesonly"}
                      target={"_blank"}
                    >
                      {" "}
                      CenterConsolesOnly YouTube Channel
                    </a>{"**"}
                    <br /><br />
                    2. <strong>In Person:</strong> By Invitation Only for
                    Registered Guests*
                  </h5>
                  <p>
                    <strong>
                      *Due to our COVID-19 protocol, which places limits on
                      attendance, submitting an RSVP does not guarantee
                      admission to the event or the date selected. Also, please
                      note that only guests with VIP Credentials will be
                      admitted.
                      <br />
                      **Make sure to subscribe to receive notifications and schedule
                    </strong>
                  </p>
                </div>
              }
              title="Event & Location Details:"
              icon={House}
              iconColor="info"
            />
          </GridItem>
          <GridItem xs={12} sm={5} className={classes.mrAuto}>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              action="/seavee-thank-you"
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <noscript>
                <p>This form won’t work with Javascript disabled</p>
              </noscript>

              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  type: "text",
                  className: "form-control",
                  name: "name",
                  placeholder: "John Doe",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  name: "email",
                  type: "text",
                  className: "form-control",
                  placeholder: "john@doe.com",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Phone Number (XXX-XXX-XXXX)"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                attributes={
                  {
                    // pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                  }
                }
                inputProps={{
                  onChange: handleChange,
                  name: "phone",
                  type: "tel",
                  className: "form-control",
                  placeholder: "305-555-5555",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Address"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  type: "text",
                  className: "form-control",
                  name: "address",
                  placeholder: "123 Main St.",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Address 2"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: false,
                }}
                inputProps={{
                  onChange: handleChange,
                  type: "text",
                  className: "form-control",
                  name: "address2",
                  placeholder: "Suite 100",
                  autoFocus: false,
                }}
              />
              <GridContainer spacing={0}>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    labelText="City"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      required: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      type: "text",
                      className: "form-control",
                      name: "city",
                      placeholder: "Miami",
                      autoFocus: false,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    labelText="State"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      required: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      type: "text",
                      className: "form-control",
                      name: "state",
                      placeholder: "Florida",
                      autoFocus: false,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    labelText="Zip Code"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                      required: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      type: "text",
                      className: "form-control",
                      name: "zip",
                      placeholder: "33133",
                      autoFocus: false,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CustomInput
                labelText="Country"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: false,
                }}
                inputProps={{
                  onChange: handleChange,
                  type: "text",
                  className: "form-control",
                  name: "country",
                  placeholder: "USA",
                  autoFocus: false,
                }}
              />
              <FormControl
                required
                fullWidth
                className={classes.selectFormControl}
                style={{ padding: "13px 0" }}
              >
                <InputLabel htmlFor={"dates"} className={classes.selectLabel}>
                  Date Requested
                </InputLabel>
                <Select
                  multiple={false}
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={state.selectedDate}
                  onChange={handleChange}
                  inputProps={{
                    name: "selectedDate",
                    id: "selectedDate",
                  }}
                >
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    Date Requested
                  </MenuItem>
                  <MenuItem
                    key={10032020}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={"10/03/2020"}
                  >
                    10/03/2020
                  </MenuItem>
                  <MenuItem
                    key={10042020}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={"10/04/2020"}
                  >
                    10/04/2020
                  </MenuItem>
                </Select>
              </FormControl>
              <input
                type={"hidden"}
                name={"subject"}
                value={"SeaVee Registration"}
              />
              <input
                type={"hidden"}
                name={"message"}
                value={`Name: ${state.name} "\r\n" Phone Number: ${state.phone} "\\r\\n" Date Requested: ${state.selectedDate} "\r\n" `}
              />
              {/*<CustomInput*/}
              {/*  labelText="Subject"*/}
              {/*  formControlProps={{*/}
              {/*    fullWidth: true,*/}
              {/*    className: classes.customFormControlClasses,*/}
              {/*    required: true,*/}
              {/*  }}*/}
              {/*  inputProps={{*/}
              {/*    onChange: handleChange,*/}
              {/*    name: "subject",*/}
              {/*    type: "text",*/}
              {/*    className: "form-control",*/}
              {/*    placeholder: "Subject...",*/}
              {/*    autoFocus: false,*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<Input*/}
              {/*  label="Description"*/}
              {/*  type="text"*/}
              {/*  name="message"*/}
              {/*  onChange={handleChange}*/}
              {/*  placeholder={"Some great question / comment here..."}*/}
              {/*  style={{ width: "100%" }}*/}
              {/*  required={true}*/}
              {/*  inputProps={{ maxLength: 5000 }}*/}
              {/*  multiline={true}*/}
              {/*  rows={10}*/}
              {/*/>*/}
              <input type="hidden" name="form-name" value="contact" />
              <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                style={{ textAlign: "-webkit-center", margin: "20px" }}
              />
              <div className={classes.textCenter}>
                <Button type="submit" round color="primary">
                  Submit RSVP
                </Button>
              </div>
            </form>
          </GridItem>
        </GridContainer>
      </Card>
    </GridContainer>
  )
}

export default withStyles(javascriptStyles)(RSVPRegistration)
