import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SeaVeeRegistration from "../components/SeaVeeRegistration";

import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"

const SeaveeRegistrationPage = (props) => {

  return (
    <Layout maxWidth={"xl"} disableGutters={true}>
      <SEO title="SeaVee 450z Unveiling RSVP Registration" />
      <SeaVeeRegistration />
    </Layout>
  )
}

export default withStyles(javascriptStyles)(SeaveeRegistrationPage)
